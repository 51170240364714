<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="封面" min-width="110" align="center">
				  <template slot-scope="scope">
					<img :src="scope.row.url" style="width: 30px;height: 30px;" @click="viewImage(scope.row,'url')" v-if="scope.row.url">
					<img src="@/assets/images/empty.png" style="width: 30px;height: 30px;" v-else>
				  </template>
				</el-table-column>
				<el-table-column label="菜单名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="链接地址" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.path }}
				  </template>
				</el-table-column>
				<el-table-column label="跳转类型" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.type === 1" size="mini" type="success">页面</el-tag>
					<el-tag v-if="scope.row.type === 2" size="mini" type="success">分类</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
			 <el-form-item label="菜单名称" prop="name">
			   <el-input v-model="form.name" placeholder="请输入菜单名称" type="text" clearable />
			 </el-form-item>
			 <el-form-item  label="跳转类型">
			  <el-radio-group v-model="form.type">
			  	<el-radio :label="1">页面</el-radio>
			  	<el-radio :label="2">分类</el-radio>
			  </el-radio-group>
			 </el-form-item>
			 <el-form-item label="链接地址" prop="path" v-if="form.type == 1">
			   <el-input v-model="form.path" placeholder="请输入链接地址" type="text" clearable />
			 </el-form-item>
			 <el-form-item label="商品分类" prop="value" v-if="form.type == 2">
			 	<el-input v-model="form.value" placeholder="请输入商品分类ID" type="number" clearable @focus="selCates"/>
			 	<small class="text-danger">为商品分类id</small>
			 </el-form-item>
			 <el-form-item label="封面" prop="cover">
				 <div class="border rounded flex-all mr-3" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;"
				 @click="chooseImage"
				 >
				   <img :src="form.url" class="w-100 h-100" v-if="form.url">
				   <i class="el-icon-plus text-muted" style="font-size: 30px;" v-else></i>
				 </div>
			 </el-form-item>
		     <el-form-item  label="状态">
		      <el-radio-group v-model="form.status">
		      	<el-radio :label="1">使用</el-radio>
		      	<el-radio :label="2">停用</el-radio>
		      </el-radio-group>
		     </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
		<!-- 选择商品分类 -->
		<el-dialog :visible.sync="openCates" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<el-table
			  :data="catesData"
			  style="width: 100%">
			  <el-table-column
			  label="ID"
			  align="center"
			  prop="id">
			  </el-table-column>
			  <el-table-column
			  prop="url"
			  align="center"
			  label="封面">
			  <template slot-scope="scope">
			   <img :src="scope.row.url" style="width: 30px;height: 30px;cursor: pointer;" @click="viewImage(scope.row,'url')" v-if="scope.row.url">
			   <img src="@/assets/images/empty.png" style="width: 30px;height: 30px;cursor: pointer;" v-else>
			  </template>
			  </el-table-column>
			  <el-table-column
			  label="名称"
			  align="center"
			  prop="name">
			  </el-table-column>
			  <el-table-column
			  width="200"
			  align="right">
			  <template slot="header" slot-scope="scope">
				<el-input
				v-model="cates_page.keyword"
				size="mini"
				placeholder="输入关键字搜索">
				  <el-button slot="append" icon="el-icon-search" size="mini" @click="getCates"></el-button>
				</el-input>
			  </template>
			  <template slot-scope="scope">
				<el-button
				type="danger"
				size="mini"
				@click="handleCatesSel(scope.$index, scope.row)">选择</el-button>
			  </template>
			  </el-table-column>
			</el-table>
			<!-- 分页 -->
			<span slot="footer" class="dialog-footer">
			  <div class="px-2" style="flex: 1;">
			   <pagination
				 :total="cates_page.total"
				 auto-scroll
				 :page.sync="cates_page.page"
				 :limit.sync="cates_page.limit"
				 @pagination="getCates"
			   />
			  </div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'menus',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				list:[],
				tree:[],
				form:{
					pid:0,
					type:1,
					name:'',
					sort:100,
					status:1,
					path:'',
					cover:'',
					url:'',
					value:'',
				},
				rules:{
					name: [
					  { required: true, message: '请输入菜单名称', trigger: 'blur' }
					],
				},
				cates_page: {
					page: 1,
					limit: 10,
					total:0,
					keyword: '',
				},
				openCates:false,
				catesData:[],
			}
		},
		mounted() {
			
		},
		methods:{
			getList() {
				this.loading = true
				this.axios.get(`/manage/${this.preUrl}/list`, {
				  params: this.queryForm
				}).then(res => {
				  this.total = res.total
				  this.list = res.data
				  this.tree = res.tree
				  this.loading = false
				}).catch(err => {
				  this.loading = false
				})
			},
			reset() {
			  this.form = {
				  pid:0,
				  type:1,
				  name:'',
				  sort:100,
				  status:1,
				  path:'',
				  cover:'',
				  url:'',
				  value:'',
			  }
			  this.resetForm('form')
			},
			chooseImage(){
				this.app.chooseImage((res)=>{
				  this.form.cover = res[0].id
				  this.form.url = res[0].url
				})
			},
			selCates(){
				this.getCates()
				this.$nextTick(()=>{
				  this.title = '分类列表'
				  this.openCates = true
				})
			},
			getCates(){
				this.axios.get('/manage/goods_cat/list',{
				  params: {
					page:this.cates_page.page,
					limit:this.cates_page.limit,
					keyword:this.cates_page.keyword,
				  }
				}).then(res=>{
				  this.cates_page.total = res.total
				  this.catesData = res.data
				})
			},
			handleCatesSel(index,item){
				this.form.value = item.id
				this.openCates = false
				this.title = '新增'
			}
		}
	}
</script>

<style>
</style>